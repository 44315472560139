import React from 'react'
import './bracket.scss'

export default class Bracket extends React.Component { 

  state = {
    bracketType: ""
  }

  bracketType = () => {
    let rounds = Math.ceil(Math.sqrt(this.props.userList.Length));
    return rounds
  }

  // createBracket = () => {
  //   switch (this.props.userList.length) {
  //     case 2:
  //       break;
  //     case 4: 
  //       break;
  //     case 8:
  //       break;
  //     case 16: 
  //       break;
  //     case 32:
  //       break;
  //   }
  // }

  render() {
    return (
      <>
        {this.props.userList.length && 
          this.props.userList.map(user => <div className="team" >{user}</div>)
        }
      </>
    )
    
  }

}


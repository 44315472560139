import React from 'react'
import './form.scss'


export default class BracketForm extends React.Component {

  finalSubmit = () => {
    alert(`there are ${this.state.userList.length} people joining this tourney.`)
    
  }

  render() {
    return (
      <>
        <h1>Lets start by adding some players!</h1>

        <ul>
          {this.props.userList.map((user, i) => (
            <li key={i}>{user}</li>
          ))}
        </ul>

        <form onSubmit={this.props.handleSubmit}> 
          <label>Name: </label>
          <input type="text" onChange={this.props.changeHandler}/>
          <input type="submit" />
        </form>

        <button className="finalSubmit" onClick={this.finalSubmit}/>
      </>
     )
  }
}
import React from "react";
import { Link } from 'react-router-dom';
import salt from "../assets/Salt.jpg";
import "./home.scss";

export default function Home () {
  return (
    <div className="splash">

            <div className="splash__content">
                <h1 className="splash__title">Welcome to the Salt Committee!</h1>
                <img src={salt} alt="" />
                <Link to='/overlay/'>
                  <h2>Click here to go to the overlay page!</h2>
                </Link>
          
            </div>

        </div>
    )
}
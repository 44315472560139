import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from './pages/home.jsx';
import Overlay from './pages/overlay.jsx';
import streamSoon from './pages/streamSoon.jsx'
import './App.scss';

export default class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch> 
          <Route path='/brb' component={streamSoon} />
          <Route path='/overlay' component={Overlay} />
          <Route exact path='/' component={Home} />
        </Switch>
      </BrowserRouter>
    )
  }
}

import React from "react";
import salt from "../assets/Salt.jpg";
import "./home.scss";

export default class Home extends React.Component {
    state = {
        currentCount: 600
    }

    time_convert = (num) => { 
        var hours = Math.floor(num / 60);  
        var minutes = num % 60;
        if (minutes < 10) {
            minutes = "0"+minutes
        }
        return hours + ":" + minutes;         
    }

    timer() {
        this.setState({
          currentCount: this.state.currentCount - 1
        })
        if(this.state.currentCount < 1) { 
          clearInterval(this.intervalId);
        }
      }
      componentDidMount() {
        this.intervalId = setInterval(this.timer.bind(this), 1000);
      }
      componentWillUnmount(){
        clearInterval(this.intervalId);
      }

    render () {
        return (
          <div className="splash">

            <div className="splash__content">
                <img className="splash__image" src={salt} alt="" />
                <h1 className="splash__title">Streaming Soon!</h1>
                <div>{this.time_convert(this.state.currentCount)}</div>

            </div>
        </div>
    )

    }
}
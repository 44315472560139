import React from 'react';
import BracketForm from '../components/form/form.jsx';
import Bracket from '../components/form/bracket.jsx'
import './overlay.scss';

export default class Project extends React.Component {
  state = {
    user: "",
    userList: []
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ userList: [...this.state.userList, this.state.user]})
    console.log(e.target.value)
    e.target.reset();
  }

  changeHandler = e => {
    this.setState({user: e.target.value})
    console.log(this.state.user) 
  }

  render() {
    return (
      <>
        <h1 className="overlay__header">Welcome to the Overlay! Stuff will go here soon™ </h1>
        <BracketForm userList={this.state.userList} handleSubmit={this.handleSubmit} changeHandler={this.changeHandler} />
        <Bracket userList={this.state.userList} />
      </>  
    )
  }
}